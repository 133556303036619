.App {
  text-align: center;
  font-weight: 400;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  /* overflow: hidden; */
}

.crop-container {
  overflow: clip;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  position: relative;
  text-align: center;
}

.crop-container video {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect-ratio-16x9 {
  padding-top: calc(6.5 / 16 * 100%);
}

.aspect-ratio-4x3 {
  padding-top: calc(3 / 4 * 100%);
}

.aspect-ratio-custom {
  padding-top: calc(4 / 16 * 100%);
}

.aspect-ratio-custom3 {
  padding-top: calc(5.5 / 16 * 100%);

}

@media only screen and (max-width: 800px) {
  .aspect-ratio-custom3 {
    padding-top: calc(1 / 16 * 100%);

  }
}

@media only screen and (max-width: 800px) {
  .fullwidth-box.video-background .fullwidth-video {
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 1;
    /* min-height: 100%; */
    /* min-width: 100%; */
    -webkit-transform-style: preserve-3d;
    /* background-color: #ffffff; */
    background-position: center center;
    background-repeat: repeat;
    /* padding-top: 40px; */
    /* padding-right: 30px; */
    /* padding-bottom: 20px; */
    /* padding-left: 30px; */
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-color: #eae9e9;
    border-top-style: solid;
    border-bottom-style: solid;
    /* transform: translate(-50%, -50%); */
    /* margin-left: -20px;
  margin-right: -20px; */
  }
}


.fullwidth-box.video-background .fullwidth-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* min-height: 100%; */
  /* min-width: 100%; */
  -webkit-transform-style: preserve-3d;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: repeat;
  /* padding-top: 40px; */
  /* padding-right: 30px; */
  /* padding-bottom: 20px; */
  /* padding-left: 30px; */
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-color: #eae9e9;
  border-top-style: solid;
  border-bottom-style: solid;
  /* transform: translate(-50%, -50%); */
  /* margin-left: -20px;
  margin-right: -20px; */
}

.centerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .aspect-ratio-4x16 {
  padding-top: calc(1 / 16 * 100%);
  } */

.AuthorizeNetSeal {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 90px;
  font: normal 9px arial, helvetica, san-serif;
  line-height: 10px;
}

.contactFormFields {
  padding: 0px 10px 0px 0px;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.info-circle {
  background: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  display: inline-block;
  height: 9px;
  position: relative;
  -webkit-transition: border .25s;
  transition: border .25s;
  width: 9px;
}

.complete {
  background: rgb(111, 224, 6);
}

.tocBox {
  border: 1px solid rgba(0, 0, 0, .125) !important;
  border-top-color: rgba(0, 0, 0, 0.125);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.125);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.125);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.directional__nav__butto_left {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.85;
  overflow: hidden;
  text-align: center;
  -webkit-transition: background .25s ease-in-out;
  transition: background .25s ease-in-out;
  visibility: visible;
  float: left;
  margin-right: 0.5em;
}

.directional__nav__button_right {

  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-size: 1.25rem;
  /* line-height: 1.85; */
  overflow: hidden;
  text-align: center;
  -webkit-transition: background .25s ease-in-out;
  transition: background .25s ease-in-out;
  visibility: visible;
  float: right;
  margin-right: 0.5em;
}

.icon-navigateleft {
  content: "mm";
}

.circle {
  position: relative;
  background: #8cc63e;
  border-radius: 50%;
  width: $size;
  height: $size;
}

.checkmark-quiz {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 35%;
  top: 45%;
  height: 150px/3;
  width: 150px/6;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

.centeredMainBanner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.centeredBanner {
  position: absolute;
  top: 50%;
  left: 70%;
  width: 100%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 800px) {
  .centeredBanner {
    left: 0;
    transform: translate(0, -50%);
  }
}

@media only screen and (max-width: 800px) {
  .centeredBanner2 {
    left: 0;
    transform: translate(-26%, -50%) !important;
  }
}

.centeredBanner2 {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.centeredBanner3 {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 50%;
  transform: translate(-50%, -50%);
}

.centeredBanner4 {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font: 40px/40px Helvetica, Sans-Serif;
}

@media (max-width: 575.98px) {
  .video {
    width: 100%;
    height: auto;
  }
}


@media (max-width: 575.98px) {
  .text {
    display: block;
  }
}

.sticky-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.disabled-pointer-events {
  pointer-events: none;
}

/* body {
  background: #212549;
  font-size: 62.5%;
  font-family: sans-serif;
  padding: 0;
  margin: 0;
} */

body {
  font-weight: bold;
  color: #000000 !important;

}

.planet {
  position: absolute;
  left: 50%;
  top: 10em;
  margin-left: -15em;
  width: 30em;
  height: 30em;
  background: #ddd;
  border-radius: 50%;
  box-shadow: inset -1.6em 0 0 0 #ccc, 0 0 1em 0 #ccc;
}

.planet .crater {
  position: absolute;
}

.planet .crater:nth-child(1) {
  left: 5.5em;
  top: 11em;
  width: 7.5em;
  height: 8em;
  border-radius: 50%;
  background: #999;
  box-shadow: inset 0.5em 0 0.1em 0 #777, -0.1em 0 0 0.1px #eee, 0.4em 0 0.2em 0 #ccc;
}

.planet .crater:nth-child(2) {
  left: 16.2em;
  top: 25em;
  width: 4em;
  height: 2.8em;
  border-radius: 50%;
  background: #999;
  -webkit-transform: rotate(-22deg);
  -moz-transform: rotate(-22deg);
  -ms-transform: rotate(-22deg);
  transform: rotate(-22deg);
  box-shadow: inset 0.2em 0.1em 0.2em 0 #777, -0.1em -0.1em 0 0.1em #eee, 0.2em 0.1em 0.2em 0 #ccc;
}

.planet .crater:nth-child(3) {
  left: 25.4em;
  top: 17em;
  width: 2em;
  height: 3em;
  border-radius: 50%;
  background: #999;
  -webkit-transform: rotate(12deg);
  -moz-transform: rotate(12deg);
  -ms-transform: rotate(12deg);
  transform: rotate(12deg);
  box-shadow: inset 0.2em 0 0.1em 0 #777, -0.1em 0 0 0.1em #eee;
}

.planet .crater:nth-child(4) {
  left: 24.4em;
  top: 24.7em;
  width: 0.8em;
  height: 3em;
  border-radius: 50%;
  -webkit-transform: rotate(48deg);
  -moz-transform: rotate(48deg);
  -ms-transform: rotate(48deg);
  transform: rotate(48deg);
  box-shadow: inset 0.4em 0.1em 0 0 #ccc, inset -0.1em 0.1em 0 0 #ccc;
  background: #999;
}

.planet .crater:nth-child(5) {
  left: 27.4em;
  top: 5.6em;
  width: 0.8em;
  height: 3.6em;
  border-radius: 50%;
  -webkit-transform: rotate(-31deg);
  -moz-transform: rotate(-31deg);
  -ms-transform: rotate(-31deg);
  transform: rotate(-31deg);
  box-shadow: inset 0.4em 0.1em 0 0 #ccc, inset -0.1em 0.1em 0 0 #ccc;
  background: #999;
}

.planet .flag {
  position: absolute;
  background: #bb0000;
  padding: 0.2em 0.3em;
  line-height: 1.2em;
  color: #eee;
  border-radius: 0 0.1em 0.1em 0;
  left: 5em;
  white-space: nowrap;
  cursor: default;
  -webkit-transform: rotate(-32deg);
  -moz-transform: rotate(-32deg);
  -ms-transform: rotate(-32deg);
  transform: rotate(-32deg);
}

.planet .flag:before {
  content: "";
  position: absolute;
  width: 0.2em;
  height: 2.7em;
  left: -0.1em;
  top: 0;
  background: #444;
}

.planet .rover {
  position: absolute;
  -webkit-transform: rotate(110deg);
  -moz-transform: rotate(110deg);
  -ms-transform: rotate(110deg);
  transform: rotate(110deg);
  left: 18.7em;
  top: 4.8em;
}

.planet .rover .body {
  position: absolute;
  z-index: 99;
  width: 0.9em;
  height: 1.5em;
  border-radius: 0.3em;
  background: #fff;
  box-shadow: -0.1em 0 0 0 #ccc;
}

.planet .rover .body:after {
  content: "";
  position: absolute;
  top: 0.4em;
  left: 0.3em;
  width: 0.5em;
  height: 0.7em;
  box-shadow: -0.2em -0.1em 0 0 #ccc;
}

.planet .rover .wheels {
  position: absolute;
  left: -0.3em;
}

.planet .rover .wheels:after,
.planet .rover .wheels:before {
  content: "";
  position: absolute;
  z-index: 9;
  background: #111;
  width: 1.4em;
  height: 0.6em;
  border-radius: 0.2em;
}

.planet .rover .wheels:after {
  top: 0.9em;
}

.planet .rover .wheels:before {
  top: 0;
}

.planet .rover .trace {
  position: absolute;
  left: -0.6em;
  top: 1.5em;
  -webkit-transform: rotate(-24deg);
  -moz-transform: rotate(-24deg);
  -ms-transform: rotate(-24deg);
  transform: rotate(-24deg);
}

.planet .rover .trace:before,
.planet .rover .trace:after {
  content: "";
  position: absolute;
  width: 1.2em;
  height: 5em;
  border-radius: 50%;
  box-shadow: inset 0.2em 0.3em 0 0 #bebebe;
}

.planet .rover .trace:after {
  left: 0;
}

.planet .rover .trace:before {
  left: 1em;
}

.message {
  position: absolute;
  color: #ddd;
  top: 44em;
  width: 100%;
  text-align: center;
  cursor: default;
  text-shadow: 0.1em 0.1em 0 #111;
}

.message p {
  display: inline-block;
  font-size: 1.6em;
}

.message p a {
  color: inherit;
  text-decoration: none;
  border-bottom: 0.1rem dotted #999;
}

.tabs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-buttons {
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding: 12px;
  border-radius: 300px;
  display: flex;
  width: 240px;
  background: repeating-linear-gradient(45deg,
      rgb(87, 132, 215),
      #054bb4);
  position: relative;
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: rgb(195, 213, 248);
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: rgb(195, 213, 248);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.tab-buttons button {
  font-size: medium;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 200px;
  color: white;
  position: relative;
  z-index: 1;
}

.tab-buttons button.active {
  color: rgb(74, 74, 74);
  background-color: white;
  z-index: 2;
}

.emphasis-button {
  background-color: #d9080b;
  font-size: large;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.emphasis-button:hover {
  background-color: darkred;
}

.my-small-font-size {
  font-size: 10px !important;
}

.accordion-button {
  font-size: 20px !important;
}



.circular-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.divider {
  width: 3px;
  background-color: white;
  height: 100%;
}

.vertical-divider {
  align-self: center;
  background-color: #d9d9d9;
  height: 1.2em;
  margin: 30px;
  width: 2px;
}

.navbar-item {
  color: #262626;
  font-size: 18px;
  font-weight: bold;
}

.shadow {
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.5);
}

.image-container {
  width: 100%;
  max-height: 500px;
  /* Adjust the maximum height of the div as needed */
  overflow: hidden;
  position: relative;
}

.custom-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.05);

  }
}


.card-image {
  width: 80%;
  height: 200px;
  object-fit: scale-down;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
}

.card-content h3 {
  margin: 0;
  font-size: 18px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn.btn-primary {
  background-color: #054BB4;
  outline: 0px;
  border-color: #054BB4;

}

.btn.btn-primary:hover {
  background-color: #106DF9;
  outline: 0px;
  border-color: #106DF9;
}

.btn.btn-primary:focus {
  background-color: #054BB4;
  box-shadow: 0 0 0 0.2rem #397adb;
}

.card-content a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  display: block;
  margin-top: 8px;
}

.staggered-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 16px;
}


.header-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #000000;
  border-radius: 30px;
  /* Adjust the radius to make it more or less rounded */
  color: #000000;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

.header-button:hover {
  background-color: #000000;
  color: #ffffff;
}

.submit-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #054BB4;
  border-radius: 30px;
  /* Adjust the radius to make it more or less rounded */
  color: #054BB4;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #054BB4;
  color: #ffffff;
}

.primary-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #054BB4 !important;
  border: 1px solid #054BB4;
  border-radius: 30px;
  /* Adjust the radius to make it more or less rounded */
  color: white;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease;
  opacity: 1 !important;
}

.primary-button:hover {
  background-color: #A0A4B6;
  border-color: #A0A4B6;
  background-color: #ffffff !important;
  color: #525151;
}

.primary-button:focus {
  /* Add this */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;
}


.form-select:focus {
  /* Accessibility Select Dropdown */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;
}

.placeholder-color:focus {
  /* Accessibility Select Dropdown */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;
}

.libButton:focus {
  /* Accessibility Select Dropdown */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;
}


.button-container {
  display: inline-flex;
  align-items: center;
}

.circle-icon {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 1.625rem;
  background-color: #1d1f20;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 10px;
  transition: all 0.3s ease;
  /* Add transition for a smooth effect */
}


.button-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 55px;
  padding-top: 8px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  transition: all 0.3s ease;
  /* Add transition for a smooth effect */
}


.button-container:hover {

  .button-text {
    color: white;
  }

  .circle-icon {
    width: 100%;
    /* Adjust the expanded width as needed */
    border-radius: 1.625rem;
    /* Adjust the border radius for the expanded circle */
  }


}

.circle-icon svg {
  color: #fff;
  font-size: 20px;
}

.sleek-button {
  width: 12rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: center;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.rotating {
  text-align: center;
}

.rotating:before,
.rotating:after {
  padding: 0;
  margin: 0;
  font-size: 54px;
  font-weight: 500;
}

.rotating .wrapper {
  box-sizing: content-box;
  background-color: #ffffff;
  height: 75px;
  padding: 50px 30px;
  display: inline-flex;
  border-radius: 8px;
  margin: 0 auto;
}

.rotating .words {
  overflow: hidden;
}

.rotating .word-span {
  text-align: left;
  font-weight: bold;
  font-size: 54px;
  display: block;
  height: 100%;
  padding-left: 10px;
  color: #054bb4;
  animation: spin_words 6s infinite;
}

@keyframes spin_words {
  10% {
    transform: translateY(-112%);
  }

  25% {
    transform: translateY(-100%);
  }

  35% {
    transform: translateY(-212%);
  }

  50% {
    transform: translateY(-200%);
  }

  60% {
    transform: translateY(-312%);
  }

  75% {
    transform: translateY(-300%);
  }

  85% {
    transform: translateY(-412%);
  }

  100% {
    transform: translateY(-400%);
  }
}

.grid-container {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.grid-container-double {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}


.story-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:hover {
    transform: scale(1.05);

  }
}

.btn-success {
  background-color: #054bb4 !important;
}

.footer {
  background-color: #1e2125;
  height: 120px
}

.automatic-tooltip {
  position: absolute;
  top: 38px;
  right: 0px;
  width: 220px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, .8);
  font-size: 14px;
  color: white;
}

h3 {
  font-weight: bold;
  color: #043a83;
}

.automatic-tooltip:before {
  position: absolute;
  right: 2px;
  top: -10px;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, .8);
  border-right: 10px solid transparent;
}

.field {
  position: relative;
  display: inline-block;
}

.trigger {
  background: none;
  border: 0;

  background-color: rgba(0, 0, 0, .75);
  color: rgba(255, 255, 255, 1);

  display: inline-block;
  top: 50%;
  transform: translate(0, 40%);
  padding: 5px 0 0 0;
  margin: -3px 0 0 5px;
  width: 20px;
  height: 20px;

  font-size: 10px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100px;
  cursor: pointer;
}

.trigger:focus {
  outline: 3px dotted black;
  outline-offset: 2px;
}

/** Make the tooltip visible on focus and on hover */
.trigger:focus+.tooltip,
.trigger:hover+.tooltip {
  opacity: 1;
}

.ql-toolbar-option {
  background-color: red;
}

.placeholder-color::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f !important;
  opacity: 1;
  /* Firefox */
  font-size: 18.66px;
}

.placeholder-color:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8f8f !important;
  opacity: 1;
  /* Firefox */
  font-size: 18.66px;
}

.placeholder-color::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f8f8f !important;
  opacity: 1;
  /* Firefox */
  font-size: 18.66px;
}

.btn.btn-primary {
  background-color: #054BB4;
  outline: 0px;
  border-color: #054BB4;

}

.btn.btn-primary:hover {
  background-color: #106DF9;
  outline: 0px;
  border-color: #106DF9;
}

.btn.btn-primary:focus {
  background-color: #054BB4;
  box-shadow: 0 0 0 0.2rem #397adb;
}


.btn.btn-outline-primary {
  color: #054BB4;
  outline: 0px;
  border-color: #054BB4;

}

.btn.btn-outline-primary:hover {
  background-color: #054BB4;
  outline: 0px;
  border-color: #054BB4;
}

.btn.btn-outline-primary:focus {

  box-shadow: 0 0 0 0.2rem #397adb;
}

div.navbar-collapse.collapse {
  text-align: center !important;
}

div.navbar-collapse.collapsing {
  text-align: center !important;
  background-color: white !important;
}


@media screen and (max-width: 990px) {
  div.navbar-collapse.collapse {
    text-align: center !important;
    background-color: white !important;
    /* background-color: rgba(49, 36, 231, 0.692) !important; */
    /* width: 60% !important; */
  }
}


div.progress {
  background-color: #929496;
}

div.progress-bar.bg-success {
  background-color: #043a83 !important;
}

p {
  opacity: .7;
  margin-bottom: 15px;
}

.close_1fc7pjq:focus {
  background-color: #929496;
}

.chapterButton {
  background-color: white;
  color: inherit;
  /* or any specific color you desire */
  border: none;
  border-color: #106DF9;
}

.chapterButton:focus {
  background-color: white;
  color: inherit;
  /* or any specific color you desire */
  border-color: #106DF9;
}


.btn.btn-secondary {
  background-color: #687178;
  outline: 0px;
  /* border-color: #687178; */

}

.btn.btn-secondary:hover {
  background-color: #757e86;
  /* outline: 0px;
   */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;


  /* box-shadow: 0 0 0 0.2rem #397adb;        */
}

.btn.btn-secondary:focus {
  /* background-color: #687178;     */
  /* box-shadow: 0 0 0 0.2rem #397adb;    */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "" !important;
  padding: 1cqmax;
}

.product-container {

  color: white;
  text-align: left;
  background: repeating-linear-gradient(45deg,
      #054bb4,
      #054bb4 60%,
      white 60%,
      white 100%);

}

.catalogueHeader {
  font-weight: bold;
  font-size: 54px;
}

@media screen and (max-width: 768px) {
  .catalogueHeader {
    font-weight: bold;
    font-size: 20px;
  }
}

.trial-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  border: 2px solid #ffffff;
  border-radius: 30px;
  /* Adjust the radius to make it more or less rounded */
  color: rgb(74, 74, 74);
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  transition: all 0.3s ease;
}

.trial-button:hover {
  border: 2px solid #000000;
  background-color: #000000;
  color: #ffffff;
}

.product-image {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 235px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 0 0.2rem #397adb;


  }
}

.chapter-image {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
}




.product-image-selected {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  max-width: 260px;
  width: 90%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: scale(1.1);
  box-shadow: 0 0 0 0.2rem #397adb;
}

.promo-video {
  border-radius: 10px;
}

.unit-image {
  width: 90%;
}


.carousel-container {
  overflow: hidden;
  height: 300px;
  width: 100%;
}

.testimonial-wrapper {
  display: flex;
}

.transition {
  transition: transform 0.5s ease-in-out;
  transform: translateX(-33.3333%);
}

.testimonial {
  width: 300px;
  flex: 1 0 33.3333%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.speech-bubble {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: #054bb4;
  border-radius: .4em;
  color: white;
  padding: 10px;
  width: 400px;
  text-align: center;
}

.speech-bubble::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #054bb4;
  border-bottom: 0;
  border-left: 0;
  margin-left: -10px;
  margin-bottom: -20px;
}

.name {
  margin-top: 10px;
  font-weight: bold;
}

.tab-sub-buttons {}

.tab-sub-buttons button {}

.tab-sub-buttons button.active {
  background-color: #054BB4 !important;
}

.sub-tabs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.form-control {
  font-size: 19px !important;
  border-radius: 20px !important;
  border: 1px solid #000000 !important;
}

.form-select {
  font-size: 19px !important;
  border-radius: 20px !important;
  border: 1px solid #000000 !important;
}

.module-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 18px;
}

@media (max-width: 600px) {
  .module-container {
    grid-template-columns: 1fr;
  }

  .breadcrumb {
    list-style: none;
    overflow: hidden;
    font: 10px Sans-Serif !important;
    justify-content: center;
  }

  h2 {
    text-align: center;
  }
}

.breadcrumb {
  list-style: none;
  overflow: hidden;
  font: 18px Sans-Serif;

}

.breadcrumb li {}

.breadcrumb li button {
  border: none;
  color: white;
  text-decoration: none;
  padding: 10px 0 10px 65px;
  background: #757e86;
  position: relative;
  display: block;
  float: left;
}

.breadcrumb li button::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid #757e86;
  ;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb li button.active::after {

  border-left: 30px solid #054BB4;
}


.breadcrumb li button::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

/* Target the audio control menu dots */
audio::-webkit-media-controls-panel {
  display: none;
}

audio::-webkit-media-controls-enclosure {
  overflow: hidden;
}

/* Initially hide the skip link */
.hidden-link {
  position: absolute;
  left: -9999px;
  /* Move it off-screen */
  top: auto;
}

/* Make it visible when focused */
.hidden-link:focus {
  position: absolute;
  /* Ensure positioning */
  left: 0;
  /* Align to the left */
  top: 1px;
  /* Optional: Add some spacing from the top */
  display: inline-block;
  /* Ensure visibility */
  background-color: #fff;
  /* Optional: Background for better visibility */
  padding: 5px;
  /* Add spacing */
  outline: 2px solid #000;
  /* Add focus outline for accessibility */
  z-index: 1000;
  /* Ensure it's above other elements */
}

.primary-button-search {
  display: inline-block;
  /* padding: 20px; */
  /* padding-right: 6px;
  padding-left: 6px; */
  border-radius: 5px;
  /* Adjust the radius to make it more or less rounded */
  background-color: #054BB4 !important;
  border: 1px solid #054BB4;
  color: white;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease;
  opacity: 1 !important;
}

.primary-button-search:hover {
  background-color: #A0A4B6;
  border-color: #A0A4B6;
  color: #000000;
}

.primary-button-search:focus {
  /* Add this */
  outline: 2px dashed rgb(37, 54, 187) !important;
  /* Example: white outline */
  outline-offset: 3px;
}