body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Roboto;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.removePadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: x-small;
  /* margin-bottom: 0!important; */
  /* border-bottom: 1px; */
}

.advert {
  font-size: small;
  margin-bottom: 0 !important;
  /* border-bottom: 1px; */
}

.saleBanner {
  font-size: medium;
  margin-bottom: 0 !important;
  text-align: left !important;
  position: absolute !important;
  top: -13px;
  left: -10px;
  border-radius: 0 !important;
  /* border-bottom: 1px; */
}

.mainImage {
  object-fit: cover;
  height: 500px;
  width: 100%;
}

footer {
  position: relative;
  bottom: 0;
  height: 370px;
}

.mainPageTryNowButton {
  width: 175px;
}
@media screen and (max-width: 768px) {
  .mainPageTryNowButton {
    font-size: 20px;
  }
}

.mainPageHeading55 {
  color: white;
  font: bold 55px/55px Helvetica, Sans-Serif;
  text-shadow: 3px 3px #0a0a0a;
  padding: 1%;
  top: 50%;

}
@media screen and (max-width: 768px) {
  .mainPageHeading55 {
    font-size: 25px;
    padding: 0;
  }
}

.mainPageHeading45 {
  color: white;
  font: bold 45px/45px Helvetica, Sans-Serif;
  text-shadow: 3px 3px #0a0a0a;
  padding: 1%;
}
@media screen and (max-width: 768px) {
  .mainPageHeading45 {
    font-size: 20px;
    padding: 0;
  }
}

.imageTextActions45 {
  color: white;
  font: 38px/38px Helvetica, Sans-Serif;
  /* text-shadow: 3px 3px #0a0a0a; */
  /* letter-spacing: -1px;   */
  /* background: rgb(0, 0, 0);  */
  /* background: rgba(0, 0, 0, 0.4); */
  /* padding: 10px; 
  position: relative; 
  top: -70vh; 
  left: 0; 
  width: 100%;  */
  /* position: absolute;  */
  /* top: 30%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

.imageTextActions10 {
  color: white;
  font: 14px/14px Helvetica, Sans-Serif;
  /* text-shadow: 3px 3px #0a0a0a; */
  line-height: 22px;
  text-align: left;

  /* background: rgb(0, 0, 0);  */
  /* background: rgba(0, 0, 0, 0.4); */
  /* padding: 10px; 
  position: relative; 
  top: -70vh; 
  left: 0; 
  width: 100%;  */
  /* position: absolute;  */
  /* top: 30%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

@media only screen and (max-width: 800px) {
  .imageTextActions10 {
    width: 100% !important;
    text-align: justify;
    /* font-weight: 500; */
    padding-left: 20px !important;
    padding-right: 20px !important;

  }
}

@media only screen and (max-width: 800px) {
  .imageTextActions10-blk {
    width: 100% !important;
    text-align: justify;
    /* font-weight: 500; */
    padding-left: 10px !important;
    padding-right: 10px !important;

  }
}

@media only screen and (max-width: 800px) {
  .imageTextActions45 {
    width: 100% !important;
    text-align: center;
    font: 25px/25px Helvetica, Sans-Serif;

  }
}

@media only screen and (max-width: 800px) {
  .imageTextActions45-blk {
    width: 80% !important;
    text-align: center;
    font: 25px/25px Helvetica, Sans-Serif !important;

  }
}

.imageTextActions45-blk {
  color: black;
  font: 38px/38px Helvetica, Sans-Serif;
  /* text-shadow: 3px 3px #0a0a0a; */
  /* letter-spacing: -1px;   */
  /* background: rgb(0, 0, 0);  */
  /* background: rgba(0, 0, 0, 0.4); */
  /* padding: 10px; 
  position: relative; 
  top: -70vh; 
  left: 0; 
  width: 100%;  */
  /* position: absolute;  */
  /* top: 30%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

.imageTextActions10-blk {
  color: black;
  font: 14px/14px Helvetica, Sans-Serif;
  /* text-shadow: 3px 3px #0a0a0a; */
  line-height: 22px;
  text-align: left;
  /* background: rgb(0, 0, 0);  */
  /* background: rgba(0, 0, 0, 0.4); */
  /* padding: 10px; 
  position: relative; 
  top: -70vh; 
  left: 0; 
  width: 100%;  */
  /* position: absolute;  */
  /* top: 30%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

.mainPageHeading35 {
  color: white;
  font: bold 35px/35px Helvetica, Sans-Serif;
  text-shadow: 2px 2px #0a0a0a;
  /* letter-spacing: -1px;   */
  /* background: rgb(0, 0, 0); 
  /* background: rgba(0, 0, 0, 0.4); */
  /* padding: 10px;  */
  /* position: relative;  */
  /* top: -40vh;  */
  /* left: 0;  */
  /* width: 100%;  */
  /* position: absolute;  */
  /* top: 60%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

.mainPageHeading25 {
  color: white;
  font: 25px/25px Helvetica, Sans-Serif;
  text-shadow: 2px 2px #0a0a0a;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .mainPageHeading25 {
    font-size: 18px;
    padding: 0px;
  }
}



.mainPagePurchaseButton {
  /* letter-spacing: -1px;   */
  /* background: rgb(0, 0, 0);  */
  /* background: rgba(0, 0, 0, 0.4); */
  /* padding: 10px;  */
  position: absolute;
  top: 50%;
  left: 50%;
  /* position: absolute;  */
  /* top: -70vh;  */
  /* left: 0;  */
  /* margin: auto!important; */
  /* text-align:center; */
  /* left: 900px; */
  /* width: 100%;  */
  transform: translate(-50%, -50%);
}

.productSchoolDropdown {
  text-align: left;
}

.display-linebreak {
  white-space: pre-line;
  text-align: left;
  font-size: 16px;
}

.lessonText {
  padding-top: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}

.lessonTitle {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}

.moduleContent {
  text-align: left;
  font-size: small;
}

.moduleTitle {
  text-align: left;
  font-size: large;
  font-weight: 600;
}

.moduleImage {
  height: auto;
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding: 10px 0px 20px 0px;
}

.figureImage {
  max-width: 100%;
}

.figures {
  text-align: left;
  padding: 10px 0px 20px 0px;
}

/* .pa-bullet-list-1 ul li:before {
  content: '\e052'!important;
  color: #2cba6c;
  font-family: 'ETMODULES'!important;
  margin-right: 10px;
  margin-left: -14px;
  font-size: 1.2em!important;
  } */

ul.checkmark {
  list-style: none;
}

ul.checkmark li:before {
  content: '✓ ';
  color: #2cba6c;
}

.centerLoadingModel {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  /* -ms-transform: translate(-50%, -50%); */
  transform: translate(-50%, -50%);
  /* border-radius: 0!important; */
}

.hideBorder {
  border: 0px !important;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.bg-custom {
  background-color: black !important;
}

.video-wrapper {
  position: relative;
  height: 100%;
}

.video-bg {
  object-fit: cover;
  height: 500px;
}

.feature_list {
  list-style: none;
}

.feature_icon {
  width: 80px;
  padding-right: 12px;
  object-fit: contain;
}

/* .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0);
}
.nav-link:focus {
  color: rgba(44, 10, 235, 0);

}
.nav-link:hover {
  color: rgba(235, 89, 10, 0);

} */

